
<template>
  <div id="page">
    <img class="bgimg" src="https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/%E7%99%BB%E5%BD%95%E9%A1%B5%E8%83%8C%E6%99%AF%E5%9B%BE/LRbg.jpg" alt="">
    <div class="cmstitle">CMS关务系统</div>
    <div id="main">
      <div id="show">
        <!-- <keep-alive> -->
        <div id="idlogin" @keydown.enter="keyDownLogin">
          <!-- <h1>这是账号登录组件</h1> -->
          <el-breadcrumb separator="|">
            <el-breadcrumb-item :class="'active'">{{'账号登录'}}</el-breadcrumb-item>
            <!-- <el-breadcrumb-item @click="commitfather('PhoneLogin')">手机登录</el-breadcrumb-item> -->
          </el-breadcrumb>
          <div id="input">
            <div id="idinput">
              <span class="name">{{'用户名'}}</span>
              <el-input v-model="inputvalue.idinput" :placeholder="'请输入用户名'" />
            </div>
            <div id="pwdinput">
              <span class="name">{{'密码'}}</span>
              <el-input maxlength="16"
              v-model="inputvalue.pwdinput" show-password :placeholder="'请输入密码'" />
            </div>
            <div id="sub">
                <el-button style="color: #ffffff"
                native-type="submit"
                @click="submitLogin"
                >{{'立即登录'}}</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="footer">
    <footer>
      <div>
        <ul>
          <li><a @click="readContract(1)">{{'大宗商品交易中心'}}</a><span>|</span></li>
          <li><a @click="readContract(2)">{{'WMS仓储系统'}}</a><span>|</span></li>
          <!-- <li @click="readContract(3)">公告<span>|</span></li> -->
          <li><a @click="readContract(4)">{{'CMS关务系统'}}</a></li>
        </ul>
      </div>
      <div>
        <p>{{'中咖数科（昆山）有限公司'}}
          <a href="https://beian.miit.gov.cn/" target="_blank"> 苏ICP备2022001170号-1 </a>
          <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32058302003278">&ensp;<img style="width: 20px;height: 20px;" src="https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/%E8%AD%A6%E5%BE%BD/%E8%AD%A6%E5%BE%BD.png"/> 苏公网安备 32058302003278号</a>
        </p>
      </div>
    </footer>
  </div>
</template>

<script>

export default {
  name: 'login',
  // inject: ['reload'],
  data () {
    return {
      inputvalue: {
        idinput: null,
        pwdinput: null
      }
    }
  },
  components: {
  },
  methods: {
    readContract (code) {
      const fileUrl = code === 1
        ? 'https://www.exp2c.com'
        : code === 2
          ? 'https://www.exp2c.com/wms/'
          : code === 3
            ? ''
            : 'https://www.baidu.com'
      window.open(fileUrl)
    },
    // 登录提交
    submitLogin () {
      console.log('登录提交')
    },
    // Enter登录提交
    keyDownLogin () {
      const that = this
      document.onkeydown = function () {
        const key = window.event.keyCode
        if (key === 13) {
          if (that.inputvalue.idinput && that.inputvalue.pwdinput) {
            that.submitLogin()
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ input {
  border: none !important;
}

div, span, a, i, li, ul, tr, td, button {
  font-size: 14px;
}

#page{
  position:absolute;
  width: 100%;
  height: 100%;
  // background: url('../../static/imges/LRbg.jpg');
  display:flex;
  justify-content:center;
  align-items: center;
  flex-flow: column;
  .bgimg{
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .cmstitle{
    z-index: 10;
    position: absolute;
    top: 200px;
    color: #FFFFFF;
    font-size: 30px;
    font-style: italic;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  }
  #main{
    z-index: 10;
    width: 460px;
    // min-width: 460px;
    background: #FFFFFF;
    box-sizing: border-box;
    #show{
      #idlogin{
        // background: palegreen;
        height: 320px;
        box-sizing: border-box;
        margin: 40px;
        display: flex;
        flex-wrap: wrap;
        .el-breadcrumb{
          padding: 8px 40px;
          width: 100%;
          .el-breadcrumb__item{
            // font-size: 20px;
            .el-breadcrumb__inner{
              color: #999999;
              font-size: 20px;
              cursor: pointer;
            }
            .el-breadcrumb__separator{
              // display: block;
              margin:0 35px;
              font-size: 14px;
              font-weight: 500;
            }
          }
          .active{
            .el-breadcrumb__inner{
              color: #333333;
            }
          }
        }
        #input{
          display: flex;
          flex-wrap: wrap;
          margin: 20px 0;
            #idinput,#pwdinput{
              margin: 15px 0;
              width: 100%;
              display: flex;
              border-bottom: 1px solid #EAEAEA;
              .name{
                height: 40px;
                line-height: 40px;
                min-width: 65px;
                font-size: 14px;
                flex-shrink: 0;
              }
              .el-input{
                .el-input__suffix{
                  .el-input__suffix-inner{
                    .el-input__icon{
                      color: #333333;
                    }
                  }
                }
              }
              input{
                height: 30px;
                flex: 1;
              }
              .icon{
                border: 1px solid black;
                margin: 0 10px;
                width: 30px;
              }
            }
          #sub{
            width: 100%;
            margin: 30px 0;
            button{
              width: 100%;
              height: 40px;
              background: linear-gradient(0deg, #280E01 0%, #673000 100%);
              span{
                font-weight: 400;
              }
            }
          }
        }
      }
    }
  }
}
#footer{
  width: 100%;
  position: absolute;
  bottom: 1px;
  footer{
    // width: 1920px;
    width: 100%;
    // height: 120px;
    display: flex;
    flex-flow: column;
    div{
      width: 100%;
      margin: 0 auto;
      text-align: center;
      height: 60px;
      line-height: 60px;
      font-size: 14px;
      font-weight: 400;
      color: #FFFFFF;
      ul{
        display: inline-block;
        li{
          float: left;
          cursor: pointer;
          a{
            transition: all .3s;
          }
          a:hover{
            color: #c88756;
          }
          span{
            margin: 0 10px;
          }
        }
      }
      p{
        width: 100%;
        display: inline-block;
        border-top: 1px solid #a8a8a846;
        a{
          transition: all .3s;
          cursor: pointer;
          color: #FFFFFF;
          img{
            // display: block;
            position: relative;
            top: 4px;
          }
        }
        a:hover{
          color: #c88756;
        }
      }
    }
  }
}
</style>
